
import search from '~/mixins/search'

export default {
  name: 'MegaMenuSearchNewLookResults',
  mixins: [
    search,
  ],

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  computed: {
    products () {
      return [...this.$search.products()].splice(0, 5)
    },

    productsGroup () {
      return this.products.reduce((acc, product) => {
        acc[product.mappedProductType] = acc[product.mappedProductType] || []
        acc[product.mappedProductType].push(product)
        return acc
      }, {})
    },

    categories () {
      return [...this.$search.category()].splice(0, 5)
    },

    articles () {
      return [...this.$search.articles()].splice(0, 5)
    },

    isSearchPage () {
      return /\/search\/q/.test(this.$route.path)
    },

    isScrolled () {
      return this.$css.breakpoints.smAndUp ? this.resultsItemsLength > 9 : this.resultsItemsLength > 7
    },
  },

  methods: {
    clickedOnProduct () {
      this.$emit('hide-results', 'clickedOnProductMobile')
    },

    boldSearchText (text, searchText) {
      return text === '' ? '' : text.replace(new RegExp(searchText, 'gi'), '<b><span style="color: var(--color-text-1);">$&</span></b>')
    },
  },
}
